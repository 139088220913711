@import '../../styles/variables';

.container {
    width: 100%;
    max-width: 1120px;
    margin: 4rem auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: $white;
    background-color: var(--white);
    position: relative;
    z-index: 3;

    .skolkovoIcon {
        align-self: flex-end;
        margin-left: auto;
        margin-right: 10px;
    }

    > .column {
        max-width: 30rem;

        &:first-of-type {
            align-items: flex-start;

            @media (max-width: 1119px) {
                padding-left: 4rem;
            }

            @media (max-width: 640px) {
                padding: 1rem 0 0 1.5rem;
            }

            @media (max-width: 320px) {
                padding: 1rem 0 0 1rem;
            }
        }

        &:nth-of-type(2) {
            align-items: flex-start;

            @media (max-width: 1119px) {
                padding-right: 4rem;
            }

            @media (max-width: 767px) {
                width: 14.5rem;
            }

            @media (max-width: 640px) {
                padding: 0 0 0 1.5rem;
            }

            @media (max-width: 320px) {
                padding: 0 0 0 1rem;
            }
        }

        > .link {
            font-size: 24px;
            line-height: 29px;
        }
    }

    @media (max-width: 1119px) {
        max-width: 100%;
    }

    @media (max-width: 767px) {
        margin: 3rem auto;
    }

    @media (max-width: 640px) {
        width: 22.5rem;
        flex-wrap: wrap;
        margin: 1rem 0 0 0;
    }

    @media (max-width: 484px) {
        margin: 0;
    }
}

.header,
.title,
.link,
.copyright {
    font-family: Gilroy, sans-serif;
    font-style: normal;
    letter-spacing: -0.025em;
    font-weight: 700;
}

.header {
    font-size: 24px;
    line-height: 29px;
    color: $gray-1;
    color: var(--gray-1);
    margin-bottom: 30px;

    @media (max-width: 640px) {
        margin: 3rem 0 1rem 0;
    }
}

.link {
    cursor: pointer;
    text-decoration: none;
    color: $blue-2;
    color: var(--blue-2);
    font-size: 18px;
    line-height: 22px;

    &:hover {
        color: $estp;
        color: var(--estp);
    }
}

.links {
    display: flex;
    width: 16.25rem;
    justify-content: space-between;

    > .column > .link {
        margin: 0 0 1.5rem 0;
    }

    @media (max-width: 320px) {
        width: 15.25rem;
    }
}

.copyright {
    margin-top: 3rem;
    color: $gray_blue;
    color: var(--gray_blue);
    font-size: 14px;
    line-height: 17px;
    opacity: 0.8;

    @media (max-width: 640px) {
        margin-bottom: 1rem;
    }
}

.column {
    display: flex;
    flex-direction: column;
}
