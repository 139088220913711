@import '../../styles/variables';

.arrowDown {
    width: 16px;
    height: 10px;
    display: inline-block;
    position: relative;
}

.arrowDown:before,
.arrowDown:after {
    content: ' ';
    position: absolute;
    width: 2px;
    height: 11px;
    background-color: $gray-1;
    background-color: var(--gray-1);
    left: 11px;
    top: 0;
    transform: rotate(45deg);
}

.arrowDown:after {
    left: 4px;
    transform: rotate(-45deg);
}

.closeIcon {
    &:hover {
        cursor: pointer;

        > g {
            opacity: 1;

            > path {
                fill: $gray-1;
                fill: var(--gray-1);
            }
        }
    }
}
