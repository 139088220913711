@import '../../../styles/variables';

.wrapper {
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

.container {
    height: 100%;
    width: 16rem;
    background: $white;
    padding: 1.5rem;
    box-shadow: -15px 10px 30px rgba(63, 169, 245, 0.5);
    display: flex;
    flex-direction: column;

    > a {
        &:visited {
            color: $gray-1;
            color: var(--gray-1);
            &:hover {
                color: $blue-2;
                color: var(--blue-2);
            }
        }

        > h1 {
            font-family: Gilroy;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 29px;
            letter-spacing: -0.025em;
            color: $blue-2;
            color: var(--blue-2);
            margin: 5.25rem 0 0.5rem 0;

            @media (max-height: 577px) {
                margin-top: 2rem;
            }

            @media (max-height: 400px) {
                margin-top: 0.25rem;
                font-size: 18px;
                line-height: 19px;
            }
        }
    }

    > svg {
        align-self: flex-end;
    }
}

.mobileMenu {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    justify-content: center;
    flex-grow: 1;
}

.menuItem {
    display: flex;

    margin: 0 0 1.5rem 0;

    @media (max-height: 500px) {
        margin-bottom: 0.25rem;
    }

    &:last-of-type {
        margin: 0;
    }
}

.item {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.menuLink,
.login > span,
.phone > span {
    font-family: $font-primary;
    font-family: var(--font-primary);
    font-style: normal;
}

.menuLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: $gray-1;
    color: var(--gray-1);
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: -0.025em;

    svg {
        height: 26px;
        margin-right: 0.75rem;
    }

    &:active,
    &:hover,
    &:visited:hover {
        color: $blue-2;
        color: var(--blue-2);
    }

    &:visited {
        color: $gray-1;
        color: var(--gray-1);
    }
}

.phone {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 6rem;

    & > span,
    > a > span {
        font-weight: 700;
        letter-spacing: 0;
    }

    & > a > span {
        font-size: 24px;
        line-height: 29px;
        color: $gray-1;
        color: var(--gray-1);
        margin-bottom: 2px;

        @media (max-height: 400px) {
            margin-bottom: 0.25rem;
            font-size: 18px;
            line-height: 19px;
        }
    }

    & > span {
        font-size: 14px;
        line-height: 17px;
        color: $gray_blue;
        color: var(--gray_blue);
    }

    @media (max-height: 649px) {
        margin-bottom: 2rem;
    }

    @media (max-height: 400px) {
        margin-bottom: 0.25rem;
    }
}

.login {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: $gray-1;
    color: var(--gray-1);
}
