@import '../../../styles/variables';

.container {
    max-width: 1120px;
    width: 100%;
    align-self: center;
    margin: 64px 0 24px 0;
    z-index: 10;

    @media (max-width: 1119px) {
        margin: 80px auto 24px 24px;
        max-width: initial;
    }

    @media (max-width: 480px) {
        margin-left: 0.75rem;
    }

    @media (max-width: 360px) {
        display: none;
    }
}

.container.withPadding {
    padding: 0 $content-padding;
    padding: 0 var(--content-padding);
}

.link,
.text {
    font-family: $font-primary;
    font-family: var(--font-primary);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: -0.025em;
    display: inline-block;
}

.link {
    padding-right: 5px;
    text-decoration: none;
    color: $blue-2;
    color: var(--blue-2);
}

.text {
    color: $gray-1;
    color: var(--gray-1);
}
