@import '../../../styles/variables';

.container {
    width: 208px;
    height: 260px;
    background-color: $white;
    background-color: var(--white);
    box-shadow: 9px 9px 45px 10px rgba(143, 210, 243, 0.1);
    border: 1px solid transparent;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
}

.logo {
    width: 100%;
    height: 159px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoImage {
    width: 120px;
    height: auto;
}

.title {
    font-family: $font-primary;
    font-family: var(--font-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0;
    height: 84px;
    padding: 24px 20px 0 20px;
    margin-bottom: 16px;
    color: $gray-2;
    color: var(--gray-2);
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
    text-align: center;
}

.delimiter {
    margin: auto;
    width: 168px;
    height: 1px;
    background-color: $gray_blue;
    background-color: var(--gray_blue);
    opacity: 0.2;
}
