@import '../../../styles/variables';

.modal {
    width: 640px;
    padding: 2rem;
    border-radius: 1rem;
    position: relative;

    @media (max-width: 640px) {
        width: 100%;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 940px) {
            width: 100%;
        }

        .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: baseline;
            width: 100%;
            margin-bottom: 2rem;

            > h1 {
                font-size: 2rem;
                font-style: normal;
                font-weight: 700;
                line-height: 38px;
                margin: 0;
            }
        }

        .body {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }
}
