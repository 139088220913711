@import '../../../styles/variables';

.container {
    width: 262px;
    box-shadow: 9px 9px 45px rgba(143, 210, 243, 0.2);
    background: $white;
    background: var(--white);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    height: 340px;
    overflow: hidden;
}

.header {
    width: 100%;
    height: 48px;
    opacity: 0.5;
    padding-left: 24px;
    display: flex;
    align-items: center;
    color: transparent;

    &:hover {
        color: $white;
        opacity: 1;
    }

    &.orange {
        background: linear-gradient(180deg, #f3d072 0%, #f79a83 98.75%);
    }

    &.green {
        background: linear-gradient(360deg, #2193b0 4.08%, #6dd5ed 100%);
    }

    &.purple {
        background: linear-gradient(0deg, #9687c0 0%, #ecbad6 100%);
    }

    &.yellow {
        background: linear-gradient(
            360deg,
            #b8860b -68.75%,
            rgba(255, 204, 0, 0.5) 168.75%
        );
    }

    & > .icon {
        width: 24px;
        height: 24px;
    }

    & > .icon * {
        stroke: $white;
        stroke: var(--white);
    }

    > span {
        display: inline-block;
        margin-left: 0.75rem;
    }
}

.content {
    padding: 24px;
    width: 100%;
    height: 233px;
    position: relative;
    overflow: hidden;

    &:after {
        position: absolute;
        bottom: 0;
        left: 24px;
        content: ' ';
        width: 214px;
        height: 1px;
        background-color: $estp;
        background-color: var(--estp);
        opacity: 0.05;
    }

    > div,
    > h1,
    > h2,
    > h3,
    > h4 {
        font-family: $font-primary;
        font-family: var(--font-primary);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0px;
        text-align: left;
        color: $gray-1;
        color: var(--gray-1);
        text-transform: uppercase;
        margin: 0 0 1rem 0;
        max-height: 50%;
        display: -webkit-box !important;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: normal;
        overflow: hidden;
    }

    > p {
        font-family: $font-primary;
        font-family: var(--font-primary);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: left;
        color: $gray-2;
        color: var(--gray-2);
        max-height: 50%;
        display: -webkit-box !important;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        white-space: normal;
        overflow: hidden;
    }
}

.footer {
    padding: 24px;
    height: 59px;
    width: 100%;
    margin-top: auto;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.date {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    color: $gray_blue;
    color: var(--gray_blue);
    margin-left: 0;
}

.link {
    width: 4rem;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin-right: 0;
    text-decoration: none;

    &:hover {
        > svg > path {
            stroke: $estp;
            stroke: var(--estp);
        }
    }
}
