@import '../styles/variables';

.button {
    display: flex;
    justify-content: center;
    font-weight: bold;
    outline: none;
    border-radius: 12px;
    cursor: pointer;
    padding: 0 22px;
}

.primary {
    background: linear-gradient(360deg, #2193b0 4.08%, #6dd5ed 100%);
    color: #ffffff;
    border: none;
}

.secondary {
    background: $white;
    background: var(--white);
    color: $gray-blue;
    color: var(--gray_blue);
    border: 1px solid $gray-blue;
    border: 1px solid var(--gray_blue);
}

.green {
    background: linear-gradient(180deg, #aad485 0%, #aad485 2%, #6cbe45 100%);
    color: #ffffff;
    border: none;
}

.blue {
    background: $blue-2;
    background: var(--blue-2);
    color: #ffffff;
    border: none;
}

.transparent {
    background: none;
    background: none;
    color: $gray-1;
    color: var(--gray-1);
    border: 1px solid $gray-1;
    border: 1px solid var(--gray-1);
}

.medium {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
}

.large {
    height: 48px;
    line-height: 48px;
    font-size: 18px;
}
