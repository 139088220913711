@import '../../../styles/variables';

.ellipse.mini {
    border-radius: 50%;
}

.ellipse.big {
    position: absolute;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ellipse.purple.mini {
    width: 558px;
    height: 558px;
    background: $gradient-ellipse-purple;
    background: var(--gradient-ellipse-purple);
    filter: drop-shadow(10px 10px 50px rgba(143, 210, 243, 0.1));
}

.ellipse.purple.big {
    top: -260px;
    right: -495px;
    width: 814px;
    height: 814px;
    background: linear-gradient(
        41.27deg,
        rgba(216, 195, 223, 0.07) 14.6%,
        rgba(149, 194, 232, 0) 84.96%
    );
    filter: drop-shadow(10px 10px 50px rgba(143, 210, 243, 0.1));
    transform: rotate(-30deg);
}

.ellipse.blue.mini {
    width: 311px;
    height: 311px;
    background: $gradient-ellipse-blue;
    background: var(--gradient-ellipse-blue);
    filter: drop-shadow(10px 10px 50px rgba(143, 210, 243, 0.1));
    transform: rotate(105deg);
}

.ellipse.blue.big {
    right: -137px;
    width: 480px;
    height: 480px;
    background: linear-gradient(
        113.96deg,
        rgba(0, 128, 168, 0.07) 0%,
        rgba(0, 194, 255, 0.1) 100%
    );
    box-shadow: 10px 10px 50px rgba(143, 210, 243, 0.1);
    transform: rotate(15deg);
}

.ellipse.red.mini {
    width: 324px;
    height: 324px;
    background: $gradient-ellipse-red;
    background: var(--gradient-ellipse-red);
    filter: drop-shadow(10px 10px 50px rgba(143, 210, 243, 0.1));
}

.ellipse.red.big {
    right: -165px;
    top: 175px;
    width: 486px;
    height: 486px;
    background: linear-gradient(
        90deg,
        rgba(245, 152, 158, 0.07) 0.19%,
        rgba(250, 208, 196, 0.07) 99.37%,
        rgba(250, 208, 196, 0.07) 100.37%
    );
    box-shadow: 10px 10px 50px rgba(143, 210, 243, 0.1);
}

.ellipse.green.mini {
    width: 324px;
    height: 324px;
    background: $gradient-ellipse-green;
    background: var(--gradient-ellipse-green);
    filter: drop-shadow(10px 10px 50px rgba(143, 210, 243, 0.1));
}

.ellipse.green.big {
    width: 486px;
    height: 486px;
    bottom: -30px;
    right: -162px;
    background: linear-gradient(
        180deg,
        rgba(170, 212, 133, 0.07) 0%,
        rgba(170, 212, 133, 0.07) 2%,
        rgba(108, 190, 69, 0.07) 100%
    );
    box-shadow: 10px 10px 50px rgba(143, 210, 243, 0.1);
}

.ellipse.lightBlue.mini {
    width: 219px;
    height: 219px;
    background: $gradient-ellipse-blue;
    background: var(--gradient-ellipse-blue);
    filter: drop-shadow(10px 10px 50px rgba(143, 210, 243, 0.1));
}

.ellipse.lightBlue.big {
    width: 329px;
    height: 329px;
    bottom: 167px;
    right: 73px;
    background: linear-gradient(
        113.96deg,
        rgba(0, 128, 168, 0.07) 0%,
        rgba(0, 194, 255, 0.1) 100%
    );
    box-shadow: 10px 10px 50px rgba(143, 210, 243, 0.1);
    transform: rotate(-15deg);
}

.ellipse.violet.mini {
    width: 558px;
    height: 558px;
    background: $gradient-ellipse-violet;
    background: var(--gradient-ellipse-violet);
    filter: drop-shadow(10px 10px 50px rgba(143, 210, 243, 0.1));
}

.ellipse.violet.big {
    width: 814px;
    height: 814px;
    top: -220px;
    left: -357px;
    background: linear-gradient(
        41deg,
        rgba(216, 195, 223, 0.07) 14.6%,
        rgba(149, 194, 232, 0) 84%
    );
    filter: drop-shadow(10px 10px 50px rgba(143, 210, 243, 0.1));
    transform: rotate(-30deg);
}

.ellipse.orange.mini {
    width: 324px;
    height: 324px;
    background: $gradient-ellipse-orange;
    background: var(--gradient-ellipse-orange);
    filter: drop-shadow(10px 10px 50px rgba(143, 210, 243, 0.1));
}

.ellipse.orange.big {
    width: 486px;
    height: 486px;
    top: 217px;
    left: -400px;
    background: linear-gradient(
        90deg,
        rgba(243, 208, 114, 0) 0%,
        rgba(247, 154, 131, 0.07) 100%
    );
    filter: drop-shadow(10px 10px 50px rgba(143, 210, 243, 0.1));
}
