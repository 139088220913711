@import './variables';
@import './font-face';
@import './modal';
@import './flatpickr';
@import './pushNotification';

:root {
    --white: #{$white};
    --gray-0: #{$gray-0};
    --gray-1: #{$gray-1};
    --gray-2: #{$gray-2};
    --gray-3: #{$gray-3};
    --gray-5: #{$gray-5};
    --gray-6: #{$gray-6};
    --gray-7: #{$gray-7};
    --gray-10: #{$gray-10};
    --gray-11: #{$gray-11};
    --gray-12: #{$gray-12};
    --blue-2: #{$blue-2};
    --blue-3: #{$blue-3};
    --blue-4: #{$blue-4};
    --blue-5: #{$blue-5};
    --blue-6: #{$blue-6};
    --blue-6-05: #{$blue-6-05};
    --estp: #{$estp};
    --gray_blue: #{$gray_blue};
    --gray_blue-0-2: #{$gray_blue-0-2};
    --light_gray: #{$light_gray};
    --green-1: #{$green-1};
    --green-2: #{$green-2};
    --orange-1: #{$orange-1};
    --red: #{$red};
    --gradient-0: #{$gradient-0};
    --gradient-0-1: #{$gradient-0-1};
    --gradient-org: #{$gradient-org};
    --gradient-org-270: #{$gradient-org-270};
    --gradient-org-90: #{$gradient-org-90};
    --gradient-info: #{$gradient-info};
    --gradient-info-005: #{$gradient-info-005};
    --gradient-1: #{$gradient-1};
    --gradient-3: #{$gradient-3};
    --gradient-6: #{$gradient-6};
    --gradient-6-01: #{$gradient-6-01};
    --gradient-7: #{$gradient-7};
    --gradient-7-01: #{$gradient-7-01};
    --gradient-8: #{$gradient-8};
    --gradient-10: #{$gradient-10};
    --gradient-11: #{$gradient-11};
    --gradient-orange-2-0: #{$gradient-orange-2-0};
    --gradient-blue-2-0: #{$gradient-blue-2-0};
    --gradient-blue-3-0: #{$gradient-blue-3-0};
    --gradient-blue-90: #{$gradient-blue-90};
    --gradient-blue-custom: #{$gradient-blue-custom};
    --gradient-ellipse-purple: #{$gradient-ellipse-purple};
    --gradient-ellipse-blue: #{$gradient-ellipse-blue};
    --gradient-ellipse-red: #{$gradient-ellipse-red};
    --gradient-ellipse-green: #{$gradient-ellipse-green};
    --grad-ellipse-lightBlue: #{$grad-ellipse-lightBlue};
    --gradient-ellipse-orange: #{$gradient-ellipse-orange};
    --gradient-ellipse-violet: #{$gradient-ellipse-violet};
    --gradient-green-90: #{$gradient-green-90};
    --gradient-violet-90: #{$gradient-violet-90};
    --gradient-orange-180: #{$gradient-orange-180};
    --gradient-gray: #{$gradient-gray};
    --gray-blue-1: #{$gray-blue-1};
    --gray-border: #{$gray-border};

    --content-padding: #{$content-padding};

    --font-primary: #{$font-primary};
    --font-secondary: #{$font-secondary};
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: $font-primary;
    font-family: var(--font-primary);
    background-color: $gray-6;
    background-color: var(--gray-6);
    width: 100%;
}

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

header,
footer {
    background-color: $white;
    background-color: var(--white);
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

a,
a:visited {
    color: $blue-2;
    color: var(--blue-2);
}
