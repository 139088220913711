body #p4s-confirm-widget {
    border-radius: 1rem;

    // Кнопка: Подписаться
    #p4s-confirm-allow-button {
        background: linear-gradient(360deg, #24abcd 4.08%, #6dd5ed 100%);
        border-radius: 12px;
    }

    // Кнопка: Отмена
    #p4s-confirm-block-button {
        cursor: pointer;
        color: #2d9cdb !important;
        border: 1px solid #2d9cdb !important;
        border-radius: 12px !important;
        background: #ffffff !important;
    }
}
