@import '../styles/variables';

.field {
    position: relative;
    border-radius: 12px;
    background-color: $gray-6;
    background-color: var(--gray-6);
    height: 56px;
    display: flex;
    flex-direction: column;
}

.input {
    position: relative;
    z-index: 3;
    border: none;
    color: $gray-1;
    color: var(--gray-1);
    margin: auto 16px 0 16px;
    background-color: transparent;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    padding: 0;

    &.error {
        border-bottom: 2px solid $red;
        border-bottom: 2px solid var(--red);
    }

    &:invalid {
        outline: none;
    }

    &:focus,
    &:valid {
        outline: none;
        border-bottom: 2px solid $blue-2;
        border-bottom: 2px solid var(--blue-2);
    }

    &:disabled {
        color: $gray-3;
        color: var(--gray-3);
    }
}

.label {
    position: absolute;
    z-index: 1;
    left: 16px;
    top: 19px;
    transition: transform 0.3s, font-size 0.3s;
    color: $gray_blue;
    color: var(--gray_blue);
}

.input:focus + .label,
.input:valid + .label,
.input:disabled + .label {
    color: $blue-2;
    color: var(--blue-2);
    transform: translateY(-9px);
    font-size: 12px;
}

.input:disabled + .label {
    color: $gray-3;
    color: var(--gray-3);
}

.input.error:not(:focus) {
    border-color: $red;
    border-color: var(--red);
}

.input.error:not(:focus) + .label {
    color: $red;
    color: var(--red);
}

.errorMessage {
    margin-left: 16px;
}
