@import '../../../styles/variables';

.container {
    width: 100%;
    position: relative;
}

.title,
.link {
    > span {
        font-family: $font-primary;
        font-family: var(--font-primary);
        font-style: normal;
        letter-spacing: 0;
    }

    &:hover {
        > span {
            color: $estp;
            color: var(--estp);
        }
        > svg {
            > path {
                stroke: $estp;
                stroke: var(--estp);
            }
        }
    }
}

.title {
    font-size: 42px;
    font-weight: 700;
    line-height: 53px;
    color: $gray-1;
    color: var(--gray-1);
}

.list {
    padding: 32px 0 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: -10px;

    @media (max-width: 480px) {
        justify-content: center;
    }
}

.item {
    margin: 10px;
}

.pagination {
    width: 100%;
    display: flex;
    margin-top: 32px;
}

.link {
    margin-left: auto;
    margin-right: 50px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.link > span {
    font-size: 14px;
    font-weight: 500;
    line-height: 15px;
    color: $blue-2;
    color: var(--blue-2);
    margin-right: 12px;
}
