@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy_SemiBold.woff2') format('woff2'),
        url('/assets/fonts/Gilroy_SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy_Medium.woff2') format('woff2'),
        url('/assets/fonts/Gilroy_Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy_Bold.woff2') format('woff2'),
        url('/assets/fonts/Gilroy_Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
