.wrapper {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;

    @media (max-width: 1119px) {
        overflow-x: hidden;
    }
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // width: 1440px;
    // margin: 0 auto;
}
