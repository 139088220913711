@import '../../styles/variables';

.wrapper {
    box-shadow: 0 10px 30px rgba(63, 169, 245, 0.1);
    z-index: 5;
}

.container {
    width: 100%;
    height: 80px;
    max-width: 1440px;
    margin: auto;
    display: flex;
    align-items: center;
    padding: 0 32px;

    @media (max-width: 1440px) {
        padding: 0 1.5rem;
    }

    @media (max-width: 360px) {
        padding: 0 1rem;
    }

    @media (max-width: 288px) {
        padding: 0 0.75rem;
    }
}

.logo {
    cursor: pointer;
    @media (max-width: 540px) {
        > svg {
            width: 94px;
            height: 43px;
        }
    }
}

.menu {
    list-style: none;
    display: flex;
    flex-grow: 1;
    padding: 0;
    justify-content: center;

    @media (max-width: 1119px) {
        display: none;
    }
}

.menuItem {
    display: flex;

    margin: 0 1.5rem 0 0;

    @media (max-width: 1440px) {
        margin: 0 1rem 0 0;
    }

    &:last-of-type {
        margin: 0;
    }
}

.item {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.menuLink,
.login > span,
.phone > a > span {
    font-family: $font-primary;
    font-family: var(--font-primary);
    font-style: normal;
}

.menuLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
    color: $gray-1;
    color: var(--gray-1);
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: -0.025em;

    svg {
        height: 26px;
    }

    &:active,
    &:hover,
    &:visited:hover {
        color: $blue-2;
        color: var(--blue-2);

        > svg > ellipse {
            stroke: $blue-2;
        }
    }

    &:visited {
        color: $gray-1;
        color: var(--gray-1);

        > svg > ellipse {
            stroke: $gray-1;
        }
    }
}

.phone {
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > a > span {
        font-weight: 700;
        letter-spacing: 0;
    }

    & > a > span:nth-of-type(1) {
        font-size: 16px;
        line-height: 19px;
        color: $gray-1;
        color: var(--gray-1);
        margin-bottom: 4px;
    }

    & > span:nth-of-type(2) {
        font-size: 12px;
        line-height: 15px;
        color: $gray_blue;
        color: var(--gray_blue);

        @media (max-width: 540px) {
            display: none;
        }
    }

    @media (max-width: 1119px) {
        flex-grow: 1;
        align-items: initial;
        margin: 0 auto 0 3rem;
    }

    @media (max-width: 540px) {
        margin: 0 1.5rem 0 5.18rem;
    }

    @media (max-width: 376px) {
        margin: 0 1.5rem 0 0;
        width: 60%;
        align-items: flex-end;
    }

    @media (max-width: 360px) {
        margin: 0 1rem;
        width: fit-content;
    }

    @media (max-width: 288px) {
        margin: 0 0.75rem;
    }
}

.delimiter {
    height: 2rem;
    width: 1px;
    background-color: $gray_blue;
    background-color: var(--gray_blue);
    margin: 0 1.5rem;
    opacity: 0.4;
    display: block;

    &.first {
        @media (max-width: 1119px) {
            display: none;
        }
    }

    &.second {
        display: none;
        @media (max-width: 1119px) {
            display: block;
        }

        @media (max-width: 540px) {
            display: none;
        }
    }
}

.login {
    margin-right: 0;
    display: flex;
    align-items: center;

    &:active,
    &:visited,
    &:hover {
        color: $blue-2;
        color: var(--blue-2);
    }

    & > span {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: -0.025em;
    }

    & > svg {
        margin-right: 8px;
    }

    @media (max-width: 540px) {
        display: none;
    }
}

.lines {
    display: none;

    @media (max-width: 1119px) {
        display: block;

        &:active,
        &:hover {
            > svg > path {
                fill: $blue-2;
                fill: var(--blue-2);
            }
        }
    }
}
